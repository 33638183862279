<template>
	<div class="row">
      <div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Gerencie o fluxo de pagamento de boletos de forma eficiente e organizada. Tenha uma visão clara de todas as suas despesas pendentes, facilitando o controle financeiro.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

		<div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col-6 col-lg-2 mb-3">
							<label><i class="far fa-store color-theme font-11 me-1"></i> Loja</label>
							<v-select name="Desktop" :options="dadosUsuario.usuarioLojas" v-model="pesquisa.loja" label="nomeFantasia" :reduce="loja => loja.id" placeholder="Todas as lojas">
								<template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
								<template v-slot:no-options>
									<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
								</template>
							</v-select>
							<select name="Mobile" class="form-control" v-model="pesquisa.loja">
								<option v-for="(option, index) in dadosUsuario.usuarioLojas" :key="index" :value="option.id">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
							</select>
                  </div>
						<div class="col-6 col-lg-2 mb-3">
							<label><i class="far fa-exchange-alt color-theme font-11 me-1"></i> Status</label>
							<v-select name="Desktop" :options="['Sem código', 'Pendente pagamento', 'Pago']" v-model="pesquisa.status" :searchable="false" placeholder="Todos os status" />
							<select name="Mobile" class="form-control" v-model="pesquisa.status">
								<option v-for="(option, index) in ['Sem código', 'Pendente pagamento', 'Pago']" :key="index" :value="option">{{ option }}</option>
							</select>
						</div>
						<div class="col-md-6 col-lg-4 mb-3">
							<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Nome/CNPJ/Chave</label>
                     <input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" v-focus @keyup.enter="buscarBoletos" placeholder="Busque por chave do boleto, chave da nota, razão social, nome ou CNPJ do emitente" />
                  </div>
						<div class="col-9 col-md-4 col-lg-3 mb-3">
                     <label><i class="far fa-calendar color-theme font-11 me-1"></i> Data vencimento</label>
                     <datePicker format="DD/MM/YYYY" :range="true" :clearable="false" :editable="false" v-model="pesquisa.data"></datePicker>
                  </div>
						<div class="col-3 col-md-2 col-lg-1 mb-3 align-self-end">
							<div class="dropdown dropstart text-end">
								<a class="dropdown-toggle dropdown-toggle-nocaret" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside">
									<i class='bx bx-dots-horizontal-rounded color-theme font-22'></i>
								</a>
								<ul class="dropdown-menu">
									<li class="dropdown-item pt-2 pb-12"><strong class="weight-500"><i class="far fa-search color-theme font-11 me-1"></i> Pesquisa</strong></li>
									<li class="dropdown-item">
										<div class="form-check form-switch mb-0">
											<input class="form-check-input" type="checkbox" id="agruparCpfCnpj" :checked="pesquisa.agruparEmitente"
												@click="pesquisa.agruparEmitente = !pesquisa.agruparEmitente; pesquisa.retorno.resultado = [];">
											<label class="form-check-label m-0 ps-1 pt-1 font-13" for="agruparCpfCnpj">Agrupar por CPF/CNPJ</label>
										</div>
									</li>
								</ul>
							</div>
							<button type="button" class="btn btn-primary w-100" @click="buscarBoletos"><i class="fas fa-search font-13"></i></button>
						</div>
               </div>
            </div>
         </div>
      </div>

		<div class="col-12" v-if="pesquisa.retorno.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.retorno.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisa.retorno.resultado.length }} resultado(s)</div>
						</div>
						<div class="w-max-content mb-2">
							<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
						</div>

						<lancamento v-for="(lancamento, index) in pesquisa.retorno.resultado" :key="index" :lancamento="lancamento" :index="index" :showEdit="false"
							@editar="abrirModal($event)" />
					</div>
				</div>
			</div>
		</div>

		<!-- modalGasto -->
      <div class="modal fade" id="modalGasto" tabindex="-1" aria-labelledby="modalGastoLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalGastoLabel">Visualizar boletos ({{ modal.nomeEmitente }})</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="row">
							<div class="col-6 px-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body p-12">
										<h1 class="font-13 mb-1 limitador-1 text-uppercase"><i class="far fa-envelope color-theme font-10 me-1"></i> Nome emitente</h1>
										<p class="font-13 mb-0 text-secondary text-end">
											<span class="limitador-1">{{ modal.nomeEmitente == null || String(modal.nomeEmitente).trim().length == 0 ? 'Não definido' : modal.nomeEmitente }}</span>
										</p>
									</div>
								</div>
							</div>
							<div class="col-6 pe-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body p-12">
										<h1 class="font-13 mb-1 limitador-1 text-uppercase"><i class="far fa-address-card color-theme font-10 me-1"></i> CPF/CNPJ emitente</h1>
										<p class="font-13 mb-0 text-secondary text-end">
											<span class="limitador-1">{{ modal.cpfCnpjEmitente == null || String(modal.cpfCnpjEmitente).trim().length == 0 ? 'Não definido' : modal.cpfCnpjEmitente }}</span>
										</p>
									</div>
								</div>
							</div>
							<div class="col-6 px-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body p-12">
										<h1 class="font-13 mb-1 limitador-1 text-uppercase"><i class="far fa-store color-theme font-10 me-1"></i> Loja destinatário</h1>
										<p class="font-13 mb-0 text-secondary text-end">
											<span class="limitador-1">
												{{ modal.lojaNome == null || String(modal.lojaNome).trim().length == 0 ? 'Sem loja' : modal.lojaNome }}<small class="mx-2">|</small>
												{{ modal.lojaNumero == null || String(modal.lojaNumero).trim().length == 0 ? '?' : modal.lojaNumero }}
											</span>
										</p>
									</div>
								</div>
							</div>
							<div class="col-6 pe-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body p-12">
										<h1 class="font-13 mb-1 limitador-1 text-uppercase"><i class="far fa-barcode color-theme font-10 me-1"></i> Chave nota</h1>
										<p class="font-13 mb-0 text-secondary text-end">
											<span class="limitador-1">{{ modal.chaveNota == null || String(modal.chaveNota).trim().length == 0 ? 'Nota não vinculada' : modal.chaveNota }}</span>
										</p>
									</div>
								</div>
							</div>
							<div class="col-6 px-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body p-12">
										<h1 class="font-13 mb-1 limitador-1 text-uppercase"><i class="far fa-calendar color-theme font-10 me-1"></i> Data</h1>
										<p class="font-13 mb-0 text-secondary text-end">
											<span class="limitador-1">{{ modal.dataNota == null || String(modal.dataNota).trim().length == 0 ? 'Sem data' : modal.dataNota }}</span>
										</p>
									</div>
								</div>
							</div>
							<div class="col-6 pe-0">
								<div class="card shadow-sm mb-1">
									<div class="card-body p-12">
										<h1 class="font-13 mb-1 limitador-1 text-uppercase"><i class="far fa-tag color-theme font-10 me-1"></i> Observações</h1>
										<p class="font-13 mb-0 text-secondary text-end">
											<span class="limitador-1">{{ modal.observacoesEmitente == null || String(modal.observacoesEmitente).trim().length == 0 ? 'Não definido' : modal.observacoesEmitente }}</span>
										</p>
									</div>
								</div>
							</div>
							<div class="col-12 px-0">
								<div class="card mb-1">
									<div class="card-body">
										<h6 class="mb-0 font-14 weight-400">{{ modal.boletos.length }} boleto(s) encontrado(s)</h6>
									</div>
								</div>
							</div>

							<boleto v-for="(boleto, index) in modal.boletos" :key="index" :boleto="boleto" :index="index" :showEdit="false" @editar="toggleBoleto($event)" />
						</div>
					</div>
					<div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

		<!-- modalBoleto -->
      <div class="modal fade" id="modalBoleto" tabindex="-1" aria-labelledby="modalBoletoLabel" aria-hidden="true">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalBoletoLabel">Atualizar boleto</h5>
                  <button type="button" class="btn-close" data-bs-toggle="modal" data-bs-target="#modalGasto" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card">
							<div class="card-body p-12">
								<div class="row">
									<div class="col-12 mb-3">
										<label><i class="far fa-barcode color-theme font-10 me-1"></i> Código boleto</label>
										<input type="text" class="form-control" v-model="boleto.codigo" />
									</div>
									<div class="col-12 align-self-center">
										<div class="form-check mb-0">
											<input type="checkbox" class="form-check-input" id="boletoPago" v-model="boleto.pago">
											<label class="form-check-label" for="boletoPago"><i class="fas fa-check font-11 color-theme mx-1"></i> Pago</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="saveBoleto">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#modalGasto">Voltar</button>
               </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import boleto from '@/components/financeiro/Boleto.vue'
import lancamento from '@/components/financeiro/Lancamento.vue'

export default {
	name: 'Plano_de_contas',
	data: function () {
      return {
			pesquisa: {
				'loja': null, 'data': [new Date(), new Date()], 'status': null, 'nome': '', 'agruparEmitente': true, 'apenasManual': false,
				'retorno': {'loja': null, 'data': null, 'status': null, 'nome': null, 'resultado': []}
			},
			modal: {"id": null, "boletos": [], "nomeEmitente": null, "cpfCnpjEmitente": null, "lojaNome": null, "lojaNumero": null, "idLojaEntrada": null, "chaveNota": null, "dataNota": null},
			boleto: {'id': null, 'codigo': '', 'dataDeVencimento': new Date(), 'manual': true, 'pago': false, 'numeroDaParcela': 1, 'totalDeParcelas': 1, 'valor': '0.00'}
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
         tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      },
      'pesquisa.data' : function (value) {
         for (let i = 0; i < value.length; i++) {
            if (!(value[i] instanceof Date)) {
               value[i] = new Date(value[i])
            }
         }
      }
	},
	components: {
      lancamento, boleto
   },
	methods: {
		exportar : function () {
			this.$store.dispatch('exportarExcel', {
				'nome': 'Lancamento_de_gastos',
				'resultado': this.pesquisa.retorno.resultado, 
				'campos': ['id', 'nomeEmitente', 'cpfCnpjEmitente', 'lojaNome', 'lojaNumero', 'chaveNota', 'dataNota', 'observacoesEmitente'], 
				'lista': {'nome': 'boletos', 'campos': ['codigo', 'dataDeVencimento', 'numeroDaParcela', 'totalDeParcelas', 'valor']}
			})
		},
		abrirModal : function (lancamento) {
			this.modal = JSON.parse(JSON.stringify(lancamento))
			$('#modalGasto').modal('show')
		},
		toggleBoleto : function (boleto) {
			this.boleto = JSON.parse(JSON.stringify(boleto))
			$('#modalGasto').modal('hide')
			$('#modalBoleto').modal('show')
      },
		buscarBoletos : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pesquisa.retorno, this.$options.data.apply(this).pesquisa.retorno)

			this.$axios({
				method: 'post',
				url: this.urlRest +'financeiro/searchBoletos',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa

			}).then(response => {
				this.pesquisa.retorno = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		saveBoleto : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'financeiro/saveBoleto',
				headers: {'Content-Type': 'application/json'},
				data: this.boleto

			}).then(() => {
				$('#modalBoleto').modal('hide')
				$('#modalGasto').modal('show')

				let index = this.modal.boletos.findIndex(b => b.id == this.boleto.id)
            this.$set(this.modal.boletos, index, JSON.parse(JSON.stringify(this.boleto)))

				this.$toast.fire({
					icon: 'success',
					title: 'Boleto salvo!'
				})
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		}
	},
	mounted() {
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
	}
}

</script>

<style scoped>

.dropdown.dropstart {
	line-height: 0.8;
}

.dropdown-menu .form-check-label {
	font-weight: 400 !important;
   text-transform: none !important;
}

</style>